import type aframe from 'aframe';
import { AnimationData } from '../@types/trigger-to-ids'

interface IAnimationControllerComponent extends Partial<aframe.Component> {
    playAnimation: Function,
    handleAnimationFinished?: any,
    currentAnimationIndex?: number,
    currentAnimationRemainingRepetitions?: number,
    animations?: Array<AnimationData>,

};

// used to only have the model loaded when needed to save memory and prevent crashes
const AnimationControllerComponent: IAnimationControllerComponent = {
    schema: {
        animationsString: {
            default: "",
            type: 'string'
        },
    },
    init: function () {
        this.playAnimation = this.playAnimation.bind(this);
        this.handleAnimationFinished = this.handleAnimationFinished.bind(this);

        this.currentAnimationIndex = 0;
        this.currentAnimationRemainingRepetitions = 0;

        // as double quotes conflict between the json syntax and the aframe component syntax
        // the string has single quotes replaced with double quotes before parsing the json
        try {
            this.animations = JSON.parse(this.data.animationsString.replaceAll("'", '"'));
        }
        catch {
            console.error('there was an error parsing the json, the following could not be passed:', this.data.animationsString.replaceAll("'", '"'))
        }

        if (this.el && this.handleAnimationFinished) {
            this.el.addEventListener('animation-finished', this.handleAnimationFinished);
        }
        // if the model has animations play the first animation
        if (this.animations && this.animations.length > 0) {
            this.playAnimation();
        }
    },
    remove() {
    },
    update(oldData) {
    },
    playAnimation() {
        if (this.animations && this.currentAnimationIndex !== undefined) {

            // no more animations to play
            if(this.animations.length <= this.currentAnimationIndex) return;

            const currentAnimation = this.animations[this.currentAnimationIndex]

            const name = currentAnimation.name;
            // -1 is used for repeat infinity
            const repeat = currentAnimation.repetitions > 0 || currentAnimation.repetitions === -1;
            const repetitions = currentAnimation.repetitions;

            if (this.el) {
                this.el.setAttribute('animation-mixer', 'clip', name);
                this.el.setAttribute('animation-mixer', 'loop', repeat ? 'repeat' : 'once');
                this.el.setAttribute('animation-mixer', 'repetitions', repetitions === -1 ? 'Infinity' : repetitions);
                this.el.setAttribute('animation-mixer', 'clampWhenFinished', 'true');
            }
            // increment the animation to play next
            this.currentAnimationIndex++;
        }
    },
    handleAnimationFinished(action: any) {
        console.log('animation finished', action);
        this.playAnimation();
    }
};

export { AnimationControllerComponent }